<template>
    <div class="x-page-container" ref="resize">
        <!-- 查询区 -->
        <div class="x-page-search">
            <x-search-item label="报损单ID" >
                <el-input v-model="search.baoSunDanID" size="small" />
            </x-search-item>
            <x-search-item label="报损时间" textWidth="238px" hasEnd>
                <el-date-picker v-model="search.baoSunSJBegin" size="small" type="datetime" />
                <el-date-picker v-model="search.baoSunSJEnd" size="small" type="datetime" slot="end"/>
            </x-search-item>
            <x-search-item label="报损人" >
                <el-input v-model="search.baoSunRen" size="small" />
            </x-search-item>
            <x-search-item label="主要事项" >
                <el-input v-model="search.title" size="small" />
            </x-search-item>
            <div class="x-search-item">
                <el-button type="primary" size="mini" @click="handleSearch">搜索</el-button>
                <el-button type="primary" size="mini" @click="handleClear">清空</el-button>
            </div>
        </div>        <!-- 列表区域 -->
        <el-table ref="table" v-loading="table.loading" :data="table.data" :height="table.height" border>
            <el-table-column width="50" label="序号" type="index" fixed/>
            <el-table-column width="180" prop="baoSunDanID" label="报损单ID" />
            <el-table-column width="180" prop="baoSunSJ" label="报损时间" />
            <el-table-column width="180" prop="baoSunShangPinZhongShu" label="报损商品种数" />
            <el-table-column width="180" prop="baoSunZongJinE" label="报损总金额" />
            <el-table-column width="180" prop="baoSunRen" label="报损人" />
            <el-table-column width="180" prop="baoSunRenID" label="报损人ID" />
            <el-table-column width="180" prop="beiZhu" label="备注" />
            <el-table-column width="180" prop="title" label="主要事项" />
            <el-table-column width="180" prop="applicant" label="申请人" />
            <el-table-column width="180" prop="applyDate" label="申请日期" />
            <el-table-column width="100" align="center" label="操作" fixed="right">
                <el-button slot-scope="{row}" type="primary" size="mini" round @click="handleAudit(row.id)">审核</el-button>
            </el-table-column>
        </el-table>
        <!-- 分页区域 -->
        <el-pagination background
                       :total="table.total"
                       @size-change="handleSizeChange"
                       @current-change="handleCurrentChange"
                       layout="total, sizes, prev, pager, next, jumper"/>
        <!-- 编辑页区域 -->
        <audit ref="audit" :audit="audit" @submitted="refresh"/>
    </div>
</template>
<script>
    import {auditList} from "@/service/kcgl/BaoSunXX";
    import XTableAudit from "@/components/x/XTableAudit";
    import Audit from "@/view/audit/BaoSunXXAudit";

    export default {
        name: "BaoSunXXAuditList",
        mixins: [XTableAudit],
        components: {Audit},
        data() {
            this.refreshService = auditList;
            return {
                search: {
                    baoSunDanID: '',
                    baoSunSJBegin: '',
                    baoSunSJEnd: '',
                    baoSunRen: '',
                    title: '',
                }
            }
        }
    }
</script>

<style scoped>
</style>